import React from "react";
import { Link, NavLink } from "react-router-dom";
import HumburgarIcon from "../../assets/images/humburger-icon.svg";
import Logo from "../../assets/images/logo.svg";
import arrow from "../../assets/images/whiteArrow.png";
import useAuth from "../../hooks/useAuth";
import MydropDown from "../mydropDown/MydropDown";
import "./Header.scss";


const Header = ({ menuOpen, setMenuOpen }) => {

    const { user } = useAuth();



    return (
        <div>
            {/* Top header */}
            <header className="header py-2 bg-gray-3 d-none d-lg-block">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center gap-3">
                        <div className="grid-item d-xl-block">
                            <div className="globalSearch">
                                <input type="search" className="globalSearchInput custom-form-control border-0 bg-transparent ff-inter" placeholder="Search here.." />
                            </div>
                        </div>
                        <div className="grid-item text-xl-center d-flex align-items-center gap-4">
                            <p className="mb-0 ff-inter text-dark3 fs-14 fw-medium">Propose a user to Join Global Life Community</p>
                            <Link to="/" className="top-header-btn">
                                propose{" "}
                                <span className="ms-1 pb-1">
                                    <img src={arrow} alt="" />
                                </span>
                            </Link>
                        </div>
                        {!user?.email && (
                            <div className="grid-item d-flex align-items-center justify-content-center justify-content-sm-end gap-3 ">
                                <Link to="/login" className="fs-12 fw-semiBold text-clr-dark-1 ff-inter text-uppercase text-decoration-none">
                                    Login
                                </Link>
                                <Link to="/signup" className="fs-12 fw-semiBold text-clr-dark-1 ff-inter text-uppercase text-decoration-none">
                                    Apply
                                </Link>
                            </div>
                        )}

                        {user?.email && <MydropDown />}
                    </div>
                </div>
            </header>

            {/* / Top header */}

            {/* Header  */}
            <div className="navigation py-3 py-md-4 bg-white">
                <div className="container">
                    <div className="nav-grid-container d-flex d-md-block align-items-center justify-content-between gap-3">
                        <div className="navbar px-3 py-4 py-md-0 px-md-0">
                            <div className="navbar-header d-flex d-md-none align-items-center justify-content-between mb-4">
                                <NavLink to="/" className="brand-logo logo is-active-mobile d-md-none text-decoration-none">
                                    <img src={Logo} alt="img" className="img-fluid" />
                                </NavLink>
                                <button className="btn btn-menu-toggle btn-default d-flex d-md-none align-items-center justify-content-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ff5f50" className="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </button>
                            </div>
                            <ul id="menu" className="d-none d-lg-flex align-items-center justify-content-between m-0 p-0 gap-5 w-100">
                                <li>
                                    <Link to="/allevents" className=" ff-inter navLink">
                                        What's happening?
                                    </Link>
                                </li>
                                <li>
                                    <Link className="menuLink ff-inter navLink">Recommendations</Link>
                                </li>
                                <li className="d-none d-md-block">
                                    <NavLink to="/" className="logo text-decoration-none">
                                        <img src={Logo} alt="img" className="img-fluid" />
                                    </NavLink>
                                </li>
                                <li>
                                    <Link className="menuLink ff-inter navLink">Trading Post</Link>
                                </li>
                                <li>
                                    <Link className="menuLink ff-inter navLink">Challenges</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="mobile-nav">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <Link to="/">
                                    <img src={Logo} alt="img" className="img-fluid" width={90} />
                                </Link>
                            </div>
                            <div>
                                <Link onClick={() => setMenuOpen(!menuOpen)}>
                                    <img src={HumburgarIcon} alt="img" className="img-fluid" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
