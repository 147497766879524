import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../assets/images/sidebar-logo.svg";
import CloseIcon from "../../assets/images/close-icon.svg";
import MydropDown from '../mydropDown/MydropDown';
import './sidebar.scss';
import useAuth from '../../hooks/useAuth';


const Sidebar = ({ menuOpen, setMenuOpen }) => {

    const {user} = useAuth();

    return (
        <div className={`side-bar ${menuOpen && "active"}`}>
            <div className="close-icon" onClick={() => setMenuOpen(false)}>
                <img src={CloseIcon} alt={CloseIcon} className="img-fluid" />
            </div>
            <div>
                <div className="logo-panel">
                    <Link to="/">
                        <img src={Logo} alt={Logo} />
                    </Link>
                </div>

                {user?.email && (
                    <div>
                        <MydropDown menuOpen={menuOpen} />
                    </div>
                )}

                <div className="main-menu p-4">
                    <ul className="d-flex flex-column align-items-center justify-content-center gap-3 list-unstyled">
                        <li>
                            <Link to="/allevents" className="ff-inter navLink text-white fs-18 text-decoration-none">
                                What's happening?
                            </Link>
                        </li>
                        <li>
                            <Link className="menuLink ff-inter navLink text-white fs-18 text-decoration-none">Recommendations</Link>
                        </li>
                        <li>
                            <Link className="menuLink ff-inter navLink text-white fs-18 text-decoration-none">Trading Post</Link>
                        </li>
                        <li>
                            <Link className="menuLink ff-inter navLink text-white fs-18 text-decoration-none">Challenges</Link>
                        </li>
                    </ul>
                </div>

                {!user?.email && (
                    <div className="grid-item d-flex align-items-center justify-content-center justify-content-sm-end gap-3 ">
                        <Link to="/login" className="fs-16 fw-semiBold text-white ff-inter text-uppercase text-decoration-none">
                            Login
                        </Link>
                        <Link to="/signup" className="fs-16 fw-semiBold text-white ff-inter text-uppercase text-decoration-none">
                            Apply
                        </Link>
                    </div>
                )}
            </div>
            <div className="bottom-link">
                <button className="login-btn">&copy; Global-Life. | All Right Reserved</button>
            </div>
        </div>
    );
};

export default Sidebar;